import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { withTranslation } from 'react-i18next';
import { isWindows, setTitle } from '../App'
import developer_000000 from '../media/developer_000000.svg';
import './Career.css';
import CareerData from './CareerData'

class Career extends React.Component {
    
    componentDidMount() {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
        setTitle(this.props.t('Common.Career'));
        document.addEventListener("keydown", this.keyDown);
    }

    componentDidUpdate() {
        setTitle(this.props.t('Common.Career'));
    }

    componentWillUnmount() {
        setTitle();
        document.removeEventListener("keydown", this.keyDown);
    }

    titlebar() {
        if (isWindows()) {
            return (<span>Job Prompt</span>);
        } else {
            return (<span>Job Terminal</span>);
        }
    }

    root() {
        if (isWindows()) {
            return (<span>C:\&gt;</span>);
        } else {
            return (<span>~&nbsp;root#&nbsp;</span>);
        }
    }

    keyDown = (event) => {
        var command = document.getElementById("currentcommand").textContent;
        if (event.key.length < 2) {
            command += event.key;
        }
        else if (event.keyCode === 8) { /* backspace */
            if (command.length < 1) {
                return; /* do nothing */
            }
            command = command.slice(0, -1);
        }
        else if (event.keyCode === 13) { /* enter */
            var answerCmd;
            switch (command.toLowerCase()) 
            {
                case "jobs":
                    answerCmd = "<br />" + ReactDOMServer.renderToString(this.jobs());
                    break;
                case "karriere.at":
                    answerCmd = "<br />" + ReactDOMServer.renderToString(this.karriere_at()) + "<br />";
                    break;
                case "linkedin.com":
                    answerCmd = "<br />" + ReactDOMServer.renderToString(this.linkedin());
                    break;
                case "contact":
                    answerCmd = "<br />" + ReactDOMServer.renderToString(this.contact()) + "<br />";
                    break;
                case "bone":
                    answerCmd = ReactDOMServer.renderToString(this.bone()) + "<br /><br />";
                    break;
                case "help":
                    answerCmd = "<pre class=\"help\">" + this.props.t("Career.HelpCommand") + "</pre><br />";
                    break;
                default:
                    var errorCmd = this.props.t("Career.UnknownCommand");
                    errorCmd = errorCmd.replace("{0}", command);
                    answerCmd = "<span class=\"unknowncommand\">" + errorCmd + "</span><br /><br />";
                    break;
            }
            var root = ReactDOMServer.renderToString(this.root());
            var previousCmds = document.getElementById("previouscommand").innerHTML;
            previousCmds = previousCmds + "<span class=\"header\">" + root + command + "</span><br />" + answerCmd;
            command = "";
            document.getElementById("previouscommand").innerHTML = previousCmds;
            document.getElementById("commandpromptinner").scrollTo(0, document.getElementById("commandpromptinner").scrollHeight);
        }
        else {
            return; /* do nothing */
        }
        document.getElementById("currentcommand").textContent = command;
    }

    jobs() {
        return (
            <div className="App-career-cmd-jobs">
                <pre>&nbsp;______________________________________________<br />
(_   ______________________________________   _)<br />
&nbsp; | |                                      | |<br />
&nbsp; | |        SOFTWARE ENTWICKLER·IN        | |<br />
&nbsp; | |     FÜR MOBILE, WEB UND BACKEND      | |<br />
&nbsp; | |                                      | |<br />
&nbsp; | |            -------------             | |<br />
&nbsp; | |            |   _   _   |             | |<br />
&nbsp; | |            |  (_) (_)  |             | |<br />
&nbsp; | |            |     &gt;     |             | |<br />
&nbsp; | |            | [\_____/] |             | |<br />
&nbsp; | |            |           |             | |<br />
&nbsp; | |            -------------             | |<br />
&nbsp; | |                                      | |<br /><br /><br /><br />

                </pre>
                <span>{this.props.t('Career.SubHeader')}</span><br /><br /><br />
                <span className="subheader">{this.props.t('Career.MobileApp.Header')}</span><br /><br />
                <span className="ul">
                    <span className="li">{this.props.t('Career.MobileApp.Experience.AppDevelopement')}</span>
                    <span className="li">{this.props.t('Career.MobileApp.Experience.Xamarin')}</span>
                    <span className="li">{this.props.t('Career.Git')}</span>
                </span><br /><br /><br />
                <span className="subheader">{this.props.t('Career.Web.Header')}</span><br /><br />
                <span className="ul">
                    <span className="li">{this.props.t('Career.Web.Experience.WebApps')}</span>
                    <span className="li">{this.props.t('Career.Web.Experience.Angular')}</span>
                    <span className="li">{this.props.t('Career.Web.Experience.Sql')}</span>
                    <span className="li">{this.props.t('Career.Web.Experience.Azure')}</span>
                    <span className="li">{this.props.t('Career.Git')}</span>
                </span><br /><br /><br />
                <span className="subheader">{this.props.t('Career.Backend.Header')}</span><br /><br />
                <span className="ul">
                    <span className="li">{this.props.t('Career.Backend.OOP')}</span>
                    <span className="li">{this.props.t('Career.Backend.WebServices')}</span>
                    <span className="li">{this.props.t('Career.Git')}</span>
                </span><br /><br /><br />
                <span className="subheader">{this.props.t('Career.Qualification.Header')}</span><br /><br />
                <span className="ul">
                    <span className="li">{this.props.t('Career.Qualification.Education')}</span>
                    <span className="li">{this.props.t('Career.Qualification.OOP')}</span>
                    <span className="li">{this.props.t('Career.Qualification.SolutionOriented')}</span>
                    <span className="li">{this.props.t('Career.Qualification.TeamPlayer')}</span>
                    <span className="li">{this.props.t('Career.Qualification.English')}</span>
                </span><br /><br /><br />
                <span className="subheader">{this.props.t('Career.Benefits.Header')}</span><br /><br />
                <span className="ul">
                    <span className="li">{this.props.t('Career.Benefits.Employment')}</span>
                    <span className="li">{this.props.t('Career.Benefits.WorkingHours')}</span>
                    <span className="li">{this.props.t('Career.Benefits.Projects')}</span>
                    <span className="li">{this.props.t('Career.Benefits.Independently')}</span>
                    <span className="li">{this.props.t('Career.Benefits.StateOfTheArt')}</span>
                    <span className="li">{this.props.t('Career.Benefits.PromotionProspects')}</span>
                    <span className="li">{this.props.t('Career.Benefits.NewTechnology')}</span>
                    <span className="li">{this.props.t('Career.Benefits.Teamevents')}</span>
                    <span className="li">{this.props.t('Career.Benefits.Amenities')}</span>
                    <span className="li">{this.props.t('Career.Benefits.Salary')}</span>
                </span><br /><br />
                <pre>&nbsp; | |                                      | |<br />
&nbsp; | |            -------------             | |<br />
&nbsp; | |            |   _   _   |             | |<br />
&nbsp; | |            |  (_) (_)  |             | |<br />
&nbsp; | |            |     &lt;     |             | |<br />
&nbsp; | |            | [\_____/] |             | |<br />
&nbsp; | |            |           |             | |<br />
&nbsp; | |            -------------             | |<br />
&nbsp;_| |______________________________________| |_<br />
(______________________________________________)<br /><br /><br /><br />
                </pre>
            </div>
        );
    }

    karriere_at() {
        return (
            <div className="App-career-cmd-karriere-at">
<pre>77777777777777777777<br />
77777^::~77777777777<br />
7777!&nbsp;&nbsp;&nbsp;^77777777777<br />
7777!.&nbsp;&nbsp;~7^::^!77777<br />
7777!.&nbsp;&nbsp;^:&nbsp;&nbsp;:!777777<br />
7777!.&nbsp;&nbsp;.&nbsp;&nbsp;:77777777<br />
7777!&nbsp;&nbsp;&nbsp;^^&nbsp;&nbsp;.~777777<br />
77777^::~7~:::~77777<br />
77777777777777777777</pre>
<span>--&gt;&nbsp;</span><a className="karriere-at" href="https://www.karriere.at/f/ebner-media-management-gmbh" target="_blank" rel="noreferrer">https://www.karriere.at/f/ebner-media-management-gmbh</a>
<br /><br />
            </div>
        );
    }

    linkedin() {
        return (
            <div className='="App-career-cmd-linkedin'>
                <pre>GPPPPPPPPPPPPPPPPPPG<br/>
                PP5~^~5P55555555555P<br/>
                PP5~:~5PPPPPPPPPP55P<br/>
                PP5!~!5Y~~77^^^!YP5P<br/>
                PP5. .5J   ~7~  :55P<br/>
                PP5. .5J  ^PP5. .5PP<br/>
                PP5. .5J  ^PP5.  YPP<br/>
                P55~~!5Y~~7PP5!~~55P<br/>
                GPPPPPPPPPPPPPPPPPPG<br/></pre>
                <span>--&gt;&nbsp;</span><a className="linkedin" href="https://www.linkedin.com/company/ebner-media-management-gmbh/jobs" target="_blank" rel="noreferrer">https://www.linkedin.com/company/ebner-media-management-gmbh/jobs</a>
                <br /><br /><br />
            </div>
        )
    }

    contact() {
        return (
            <div className="App-career-cmd-contact">
                <span>{this.props.t('Career.Contact.Interest')}</span><br /><br />
                <span className="subheader">{this.props.t('Career.Contact.Title')}</span><br />
                <span>Gregor Stritzinger</span><br />
                <span>Tel: +43 7242 252050</span><br />
                <span>E-Mail: gregor.stritzinger@emmgt.at</span><br /><br />
                <pre>&nbsp;                                  .''.       <br />
                &nbsp;      .''.      .        *''*    :_\/_:     . <br />
                &nbsp;     :_\/_:   _\(/_  .:.*_\/_*   : /\ :  .'.:.'.<br />
                &nbsp; .''.: /\ :   ./)\   ':'* /\ * :  '..'.  -=:o:=-<br />
                &nbsp;:_\/_:'.:::.    ' *''*    * '.\'/.' _\(/_'.':'.'<br />
                &nbsp;: /\ : :::::     *_\/_*     -= o =-  /)\    '  *<br />
                &nbsp; '..'  ':::'     * /\ *     .'/.\'.   '<br />
                &nbsp;     *            *..*         :<br />
                &nbsp;       *<br />
                &nbsp;       *
                </pre>
            </div>
        );
    }

    bone() {
        return (
<span>
&nbsp;_&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;_<br />
(_'-----'_)<br />
(_.'""""._)
</span>
        );
    }

    bonecomment() {
        if (isWindows()) {
            return <div dangerouslySetInnerHTML={{ __html: `<!-- 5p3c141 h1dd3n j08 pr0mp7 c0mm4nd: bone -->` }} hidden/>
        } else {
            return <div dangerouslySetInnerHTML={{ __html: `<!-- 5p3c141 h1dd3n j08 73rm1n41 c0mm4nd: bone -->` }} hidden/>
        }
    }

    render() {
        return (
            <div className="App-career">
                <CareerData />
                {/*<div className="App-career-marquee">
                    <div className="App-career-marquee-inner">
                        <span dangerouslySetInnerHTML={{ __html: this.props.t('Career.Marquee')}}></span>
                    </div>
                </div>*/}
                <div id="commandprompt" className={`App-career-cmd${isWindows() ? ' windows' : ''}`}>
                    <div className="App-career-cmd-titlebar">
                        <img src={developer_000000} alt="developer" />
                        <span>{this.titlebar()}</span>
                    </div>
                    <div id="commandpromptinner" className="App-career-cmd-inner font-monospace">
                        <span className="subheader">{this.props.t('Career.Jobs')}</span><br />
                        <span>(c) EBNER media&amp;management GmbH. All rights reserved.</span><br /><br /><br /><br />
                        <span className="header">{this.root()}jobs</span><br /><br />
                        {this.jobs()}
                        <span className="header">{this.root()}karriere.at</span><br /><br />
                        {this.karriere_at()}<br />
                        <span className="header">{this.root()}linkedin.com</span><br /><br />
                        {this.linkedin()}<br />
                        <span className="header">{this.root()}contact</span><br /><br />
                        {this.contact()}<br />
                        <div id="previouscommand"></div>
                        <span className="header">{this.root()}<span id="currentcommand" className="command"></span><div className="cursor-blink"></div></span>
                    </div>
                </div>
                <div id="bone" className="delete-this-class-to-make-the-bone-visible">{this.bonecomment()}{this.bone()}</div>
            </div>
        );
    }
}

export default withTranslation()(Career);
