import React from 'react';
import { withTranslation } from 'react-i18next';
import emm from '../media/logo_f7a823.svg';
import he from '../media/he.svg';
import pos from '../media/pos.svg';
//import iphone from '../media/iphone3gs.svg';
//import wien from '../media/wienwappen.svg';
import './HomeCompanyHistory.css';

class HomeCompanyHistory extends React.Component {
    render() {
        return (
            <div className="App-home-company-history">
                {/* 
                <div>Unser Ziel ist es eine langjährige Partnerschaft mit unseren Kunden aufzubauen, indem wir qualitative und individuelle Softwarelösungen in ansprechendem Design bieten. Wir legen Wert auf benutzerfreundliche, leicht verständliche und stabil laufende Software. Komplex im Hintergrund und dennoch einfach zu bedienen.</div>
                */}
                <div className="App-home-company-history-entry">
                    <div className="App-home-company-history-entry-left">
                        <span className="eurostile-condensed-heavy">1975</span>
                    </div>
                    <div className="App-home-company-history-entry-right">
                        <div className="App-home-company-history-entry-right-img">
                            <img src={he} alt="HE" />
                        </div>
                        <div className="App-home-company-history-entry-right-header eurostile-condensed-heavy">
                            <span>{this.props.t('HomeCompanyHistory.Part1.Title')}</span>
                        </div>
                        <div className="App-home-company-history-entry-right-text">
                            <p>{this.props.t('HomeCompanyHistory.Part1.Content')}</p>
                        </div>
                    </div>
                </div>
                <div className="App-home-company-history-entry">
                    <div className="App-home-company-history-entry-left">
                        <span className="eurostile-condensed-heavy">2005</span>
                    </div>
                    <div className="App-home-company-history-entry-right">
                        <div className="App-home-company-history-entry-right-img">
                            <img src={emm} alt="EMM" />
                        </div>
                        <div className="App-home-company-history-entry-right-header eurostile-condensed-heavy">
                            <span dangerouslySetInnerHTML={{ __html: this.props.t('HomeCompanyHistory.Part2.Title') }}></span>
                        </div>
                        <div className="App-home-company-history-entry-right-text">
                            <p dangerouslySetInnerHTML={{ __html: this.props.t('HomeCompanyHistory.Part2.Content') }}></p>
                        </div>
                    </div>
                </div>
                <div className="App-home-company-history-entry">
                    <div className="App-home-company-history-entry-left noimage">
                        <span className="eurostile-condensed-heavy" dangerouslySetInnerHTML={{ __html: this.props.t('HomeCompanyHistory.Part3.Date') }}></span>
                    </div>
                    <div className="App-home-company-history-entry-right">
                        <div className="App-home-company-history-entry-right-header eurostile-condensed-heavy">
                            <span>{this.props.t('HomeCompanyHistory.Part3.Title1')}</span>
                        </div>
                        <div className="App-home-company-history-entry-right-text">
                            <p>{this.props.t('HomeCompanyHistory.Part3.Content1')}</p>
                            {/* <p>{this.props.t('HomeCompanyHistory.Part3.Content2')}</p> */}
                        </div>
                        {/* <div className="App-home-company-history-entry-right-header eurostile-condensed-heavy" style={{paddingTop:'15px'}}>
                            <span>{this.props.t('HomeCompanyHistory.Part3.Title2')}</span>
                        </div>
                        <div className="App-home-company-history-entry-right-text">
                            <p>{this.props.t('HomeCompanyHistory.Part3.Content3')}</p>
                        </div> */}
                    </div>
                </div>
                <div className="App-home-company-history-entry">
                    <div className="App-home-company-history-entry-left">
                        <span className="eurostile-condensed-heavy">2008</span>
                    </div>
                    <div className="App-home-company-history-entry-right">
                        <div className="App-home-company-history-entry-right-img">
                            <img src={pos} alt="POS" />
                        </div>
                        <div className="App-home-company-history-entry-right-header eurostile-condensed-heavy">
                            <span>{this.props.t('HomeCompanyHistory.Part4.Title')}</span>
                        </div>
                        <div className="App-home-company-history-entry-right-text">
                            <p>{this.props.t('HomeCompanyHistory.Part4.Content')}</p>
                        </div>
                    </div>
                </div>
                <div className="App-home-company-history-entry">
                    <div className="App-home-company-history-entry-left noimage">
                        <span className="eurostile-condensed-heavy" dangerouslySetInnerHTML={{ __html: this.props.t('HomeCompanyHistory.Part5.Date') }}></span>
                    </div>
                    <div className="App-home-company-history-entry-right">
                        <div className="App-home-company-history-entry-right-header eurostile-condensed-heavy">
                            <span>{this.props.t('HomeCompanyHistory.Part5.Title')}</span>
                        </div>
                        <div className="App-home-company-history-entry-right-text">
                            <p>{this.props.t('HomeCompanyHistory.Part5.Content1')}</p>
                            <p>{this.props.t('HomeCompanyHistory.Part5.Content2')}</p>
                            <p>{this.props.t('HomeCompanyHistory.Part5.Content3')}</p>
                        </div>
                    </div>
                </div>
                {/* <div className="App-home-company-history-entry">
                    <div className="App-home-company-history-entry-left noimage">
                        <span className="eurostile-condensed-heavy">2010</span>
                    </div>
                    <div className="App-home-company-history-entry-right">
                        
                        <div className="App-home-company-history-entry-right-header eurostile-condensed-heavy">
                            <span>{this.props.t('HomeCompanyHistory.Part6.Title')}</span>
                        </div>
                    </div>
                </div> */}
                <div className="App-home-company-history-entry">
                    <div className="App-home-company-history-entry-left noimage">
                        <span className="eurostile-condensed-heavy" dangerouslySetInnerHTML={{ __html: this.props.t('HomeCompanyHistory.Part7.Date') }}></span>
                    </div>
                    <div className="App-home-company-history-entry-right">
                        <div className="App-home-company-history-entry-right-header eurostile-condensed-heavy">
                            <span className="eurostile-condensed-heavy" dangerouslySetInnerHTML={{ __html: this.props.t('HomeCompanyHistory.Part7.Title') }}></span>
                        </div>
                        <div className="App-home-company-history-entry-right-text">
                            <p>{this.props.t('HomeCompanyHistory.Part7.Content1')}</p>
                            <p>{this.props.t('HomeCompanyHistory.Part7.Content2')}</p>
                        </div>
                    </div>
                </div>
                {/* <div className="App-home-company-history-entry">
                    <div className="App-home-company-history-entry-left noimage">
                        <span className="eurostile-condensed-heavy">2015</span>
                    </div>
                    <div className="App-home-company-history-entry-right">
                        <div className="App-home-company-history-entry-right-header eurostile-condensed-heavy">
                            <span>{this.props.t('HomeCompanyHistory.Part8.Title')}</span>
                        </div>
                    </div>
                </div> */}
                <div className="App-home-company-history-entry">
                    <div className="App-home-company-history-entry-left noimage">
                        <span className="eurostile-condensed-heavy" dangerouslySetInnerHTML={{ __html: this.props.t('HomeCompanyHistory.Part9.Date') }}></span>
                    </div>
                    <div className="App-home-company-history-entry-right">
                        <div className="App-home-company-history-entry-right-header eurostile-condensed-heavy">
                            <span>{this.props.t('HomeCompanyHistory.Part9.Title')}</span>
                        </div>
                        <div className="App-home-company-history-entry-right-text">
                            <p>{this.props.t('HomeCompanyHistory.Part9.Content1')}</p>
                            <p>{this.props.t('HomeCompanyHistory.Part9.Content2')}</p>
                            <p>{this.props.t('HomeCompanyHistory.Part9.Content3')}</p>
                            <p dangerouslySetInnerHTML={{ __html: this.props.t('HomeCompanyHistory.Part9.Content4') }}></p>
                            <p>{this.props.t('HomeCompanyHistory.Part9.Content5')}</p>
                            <p>{this.props.t('HomeCompanyHistory.Part9.Content6')}</p>
                        </div>
                    </div>
                </div>
                {/*  <div className="App-home-company-history-entry">
                    <div className="App-home-company-history-entry-left noimage">
                    <span className="eurostile-condensed-heavy" dangerouslySetInnerHTML={{ __html: this.props.t('HomeCompanyHistory.Part10.Date')}}></span>
                    </div>
                    <div className="App-home-company-history-entry-right">
                        <div className="App-home-company-history-entry-right-header eurostile-condensed-heavy">
                            <span>{this.props.t('HomeCompanyHistory.Part10.Title')}</span>
                        </div>
                        <div className="App-home-company-history-entry-right-text">
                            <p>{this.props.t('HomeCompanyHistory.Part10.Content1')}</p>
                            <p>{this.props.t('HomeCompanyHistory.Part10.Content2')}</p>
                        </div>
                    </div>    
                </div>
                <div className="App-home-company-history-entry">
                    <div className="App-home-company-history-entry-left noimage">
                        <span className="eurostile-condensed-heavy">2022</span>
                    </div>
                    <div className="App-home-company-history-entry-right">
                        <div className="App-home-company-history-entry-right-header eurostile-condensed-heavy">
                            <span>{this.props.t('HomeCompanyHistory.Part11.Title')}</span>
                        </div>   
                        <div className="App-home-company-history-entry-right-text">
                            <p>{this.props.t('HomeCompanyHistory.Part11.Content')}</p>
                        </div>
                    </div>    
                </div> */}
                <div className="App-home-company-history-entry">
                    <div className="App-home-company-history-entry-left noimage">
                        <span className="eurostile-condensed-heavy">2024</span>
                    </div>
                    <div className="App-home-company-history-entry-right">
                        <div className="App-home-company-history-entry-right-header eurostile-condensed-heavy">
                            <span>{this.props.t('HomeCompanyHistory.Part12.Title')}</span>
                        </div>
                        <div className="App-home-company-history-entry-right-text">
                            <p>{this.props.t('HomeCompanyHistory.Part12.Content')}</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(HomeCompanyHistory);